/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Col, Row, message, Table, Spin, Tag } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  fetchPhotographerShoots,
  fetchPhotographerByUserId,
  fetchDistanceArrayAndTotal,
} from "../../../redux/action/photographerAction";
import { updateInvoice } from "../../../redux/action/invoiceAction";
import {
  getShootColor,
  roundedCurrency,
  formatUsAddress,
  roundOffNumber,
} from "../../../shared/utils/utils";
import { photoCodes } from "../../../shared/payBreakdown/photographersPayCalc";
import NumberFormat from "react-number-format";
import ability from "../../../user/Component/Auth/ability";
import queryString from "query-string";
import InlineEdit from "../../../shared/Components/InlineEdit";
import { updatePhotographerForWeekTotal } from "../../../redux/action/photographer";
import WeeklyPayOverview from "./WeeklyPayOverview";
const effectiveDate = "2022-04-04";
const effectiveBasePayDate = "2023-03-01";

const isPastEffectiveDate = (shoot) => {
  const effectiveDateValue = new Date(effectiveDate);
  const fetchedDateValue = new Date(shoot?.events?.start);
  return fetchedDateValue >= effectiveDateValue;
};

const isPastEffectiveBasePayDate = (shoot) => {
  const effectiveDateValue = new Date(effectiveBasePayDate);
  const fetchedDateValue = new Date(shoot?.events?.start);
  return fetchedDateValue >= effectiveDateValue;
};
const PayBreakdown = ({
  handleCurrentPath,
  fetchPhotographerShoots,
  updatePhotographerForWeekTotal,
  fetchDistanceArrayAndTotal,
  auth,
  fetchPhotographerByUserId,
  photographer: { currentPhotographer, photographerShoots },
  match,
  updateInvoice,
  setDateRange,
}) => {
  const costMultiplierForDist = 0.15;
  const [time, setTime] = useState({
    start: "",
    end: "",
  });
  const [value, setValue] = useState();
  const [grandTotal, setGrandTotal] = useState(0);
  const [range, setRange] = useState([]);
  const [filteredShoot, setFilteredShoot] = useState([]);
  const [loadingWeeklyTotal, setLoadingWeeklyTotal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [test, setTest] = useState();
  const [distTravCost, setDistTravCost] = useState(null);
  const [distTravFns, setDistTravFns] = useState({});
  const [weekTotal, setweekTotal] = useState(0);
  const [weeklyDistance, setWeeklyDistance] = useState(0);
  let history = useHistory();
  useEffect(() => {
    if (setDateRange && time.start && time.end) {
      setDateRange({ from: time.start.clone(), to: time.end.clone() });
    }
  }, [time]);
  useEffect(() => {
    const fetchForPhtgrWeekTotal = async (userId) => {
      if (userId) {
        //update

        setTimeout(() => {
          updatePhotographerWeekTotal();
        }, 10000);
      } else {
        message.error("Oops ! Something went wrong.");
      }
    };
    if (time.start !== "" && time.end !== "") {
      fetchForPhtgrWeekTotal(currentPhotographer?._id);
    }
  }, [weekTotal]);
  useEffect(() => {
    const fetchForPhtgr = async (userId) => {
      const phtgr = await fetchPhotographerByUserId(userId);
      if (phtgr.data && phtgr.data._id) {
        fetchPhotographerShoots(
          phtgr.data._id,
          "status=Scheduled:Pending:Completed:Reschedule:Tentative:Processing:Training"
        );
      } else {
        message.error("Oops ! Something went wrong.");
      }
    };
    auth?.user?.access?.includes("PHOTOGRAPHER") &&
      handleCurrentPath("Pay Breakdown", "3");
    const fetchPgShoots = async () => {
      const shoots = await fetchPhotographerShoots(
        currentPhotographer?._id,
        "status=Scheduled:Completed:Reschedule:Tentative:Processing:Training"
      );
      setLoader(false);
    };
    if (match !== undefined && currentPhotographer?._id === match) {
      setLoader(true);
      fetchPgShoots();
      setLoader(false);
    } else if (auth.user.access.includes("PHOTOGRAPHER")) {
      setLoader(true);
      fetchForPhtgr(auth?.user?._id);
      setLoader(false);
    }
  }, [currentPhotographer?._id, match, updateInvoice, test]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePhotographerWeekTotal = async () => {
    setLoadingWeeklyTotal(true);
    const tempObj = {
      data: {
        startDate: moment(time.start).format("MM-DD-YY"),
        endDate: moment(time.end).format("MM-DD-YY"),
        weekTotal: parseFloat(weekTotal),
      },
      userId: currentPhotographer?._id,
    };
    await updatePhotographerForWeekTotal(tempObj);
    setLoadingWeeklyTotal(false);
  };

  // Calculate the weeklyDistance based on the distTravFns
  useEffect(() => {
    let tempWeeklyDistance = 0;
    for (const key in distTravFns) {
      tempWeeklyDistance += distTravFns[key];
    }
    setWeeklyDistance(tempWeeklyDistance);
  }, [distTravFns]);

  useEffect(() => {
    let total = 0;
    if (filteredShoot.length) {
      filteredShoot.forEach((shoot) => {
        total += getTotalValue(shoot, shoot.invoice);
      });
    }
    setweekTotal(total.toFixed(2));
  }, [filteredShoot, distTravFns]);

  const getTotalValue = (val, invoices) => {
    const value =
      val.status === "Completed" ||
      val.status === "Processing" ||
      val.status === "Training" ||
      val.status === "Reschedule"
        ? roundOffNumber(invoices[0].photographer_total_pay) +
          (val.status !== "Reschedule" &&
          val.status !== "Tentative" &&
          val.status !== "Training"
            ? roundOffNumber(getBasePay(currentPhotographer, val.events.start))
            : 0) +
          roundOffNumber(
            (val.status === "Completed" ||
              val.status === "Processing" ||
              val.status === "Tentative" ||
              val.status === "Reschedule" ||
              val.status === "Scheduled" ||
              val.status === "Training") &&
              isPastEffectiveDate(val)
              ? travelStipendAmount
              : 0
          ) +
          roundOffNumber(distTravFns[val._id] * costMultiplierForDist) +
          // Add extra compensation if square footage is greater than 4000
          (val.booking_form?.sqft >= 4000 ? 15 : 0)
        : invoices[0].photographer_total_pay +
          (val.status !== "Reschedule" &&
          val.status !== "Tentative" &&
          val.status !== "Training"
            ? getBasePay(currentPhotographer, val.events.start)
            : val.status === "Reschedule" || val.status === "Training"
            ? roundOffNumber(travelStipendAmount)
            : 0);
    return value;
  };

  const getPayValue = (record) => {
    const payValue =
      record.status === "Completed" ||
      record.status === "Processing" ||
      record.status === "Reschedule" ||
      record.status === "Training"
        ? roundOffNumber(record.invoice[0]?.photographer_total_pay) +
          (record.status !== "Reschedule" &&
          record.status !== "Tentative" &&
          record.status !== "Training"
            ? roundOffNumber(
                getBasePay(currentPhotographer, record.events.start)
              )
            : 0) +
          roundOffNumber(
            (record.status === "Completed" ||
              record.status === "Processing" ||
              record.status === "Tentative" ||
              record.status === "Reschedule" ||
              record.status === "Scheduled" ||
              record.status === "Training") &&
              isPastEffectiveDate(record)
              ? travelStipendAmount
              : 0
          ) +
          roundOffNumber(distTravFns[record._id] * costMultiplierForDist) +
          // Add extra compensation if square footage is greater than 4000
          (record.booking_form?.sqft >= 4000 ? 15 : 0) // Ensure the correct field for sqft is used
        : roundedCurrency(
            record.invoice[0].photographer_total_pay +
              (record.status !== "Reschedule" &&
              record.status !== "Tentative" &&
              record.status !== "Training"
                ? getBasePay(currentPhotographer, record.events.start)
                : record.status === "Reschedule"
                ? roundOffNumber(travelStipendAmount)
                : 0)
          );
    return payValue;
  };

  const setTester = (value) => {
    setTest(value);
  };
  const travelStipendAmount = 5;
  const expandedRowRender = (val) => {
    const invoices = val.invoice;
    const data = [];
    for (let i = 0; i < invoices?.length; i++) {
      for (let j = 0; j < invoices[i].products.length; j++) {
        data.push(invoices[i].products[j]);
      }
    }

    const effectiveDateValue = new Date(effectiveDate);
    const fetchedDateValue = new Date(filteredShoot[0]?.events?.start);
    const dateComparison = fetchedDateValue >= effectiveDateValue;
    return (
      <Row>
        {ability.can("read", "Invoices") ? (
          <Col flex="auto" className="p-2 invoice-breakdown">
            <div className="line-item">
              <h4>Invoice Breakdown</h4>
              <span
                style={{
                  marginLeft: 15,
                  fontSize: 12,
                  color: "tomato",
                  padding: 3,
                }}
              >
                {val.original_hsn ? `Original HS# ${val.original_hsn}` : null}
              </span>
            </div>

            <div className="line-item p-2">
              <span>
                <strong>Description of Services</strong>
              </span>
              <span>
                <strong>Price</strong>
              </span>
            </div>
            <ul className="striped-list">
              {data
                .filter(
                  (product) =>
                    product.code !== "RSC" &&
                    product.code !== "RFC" &&
                    product.code !== "UC"
                )
                .map((product) => (
                  <li key={product._id}>
                    <div className="line-item">
                      <span>{product.description}</span>
                      <span>{roundOffNumber(product.price)}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </Col>
        ) : null}
        <Col flex="auto" className="p-2 pay-breakdown">
          <div className="line-item">
            <h4>Pay Breakdown</h4>
            <div>
              <strong style={{ color: "#1079C6" }}>
                HS# {val.hsn} -{" "}
                {`${val.client_name_first} ${val.client_name_last}`}
              </strong>
            </div>
          </div>
          {val.status !== "Reschedule" &&
          val.status !== "Tentative" &&
          val.status !== "Training" ? (
            <Col span={24} className="p-2">
              <div className="line-item">
                <span>Base</span>
                <span>
                  {getBasePay(currentPhotographer, val.events.start).toFixed(2)}
                </span>
              </div>
            </Col>
          ) : null}
          <ul className="striped-list">
            {data
              .map((product, index) =>
                product.photographer_pay != null ? (
                  photoCodes?.includes(product.code) &&
                  (product.photographer_pay > 0 || product.code === "UC") ? (
                    <li key={index}>
                      <div className="line-item">
                        <span>{product.description}</span>
                        <span>
                          <InlineEdit
                            test={setTester}
                            value={product.photographer_pay.toFixed(2)}
                            setValue={setValue}
                            selectedItem={product}
                            invoice={invoices[0]}
                            updateInvoice={updateInvoice}
                          />
                        </span>
                      </div>
                    </li>
                  ) : null
                ) : (
                  "NO DATA"
                )
              )
              .concat(
                val.booking_form?.sqft >= 4000 ? (
                  <li key={"extra-compensation"}>
                    <Col span={24} className="p-2">
                      <div className="line-item">
                        <span>4,000 sqft+ Compensation</span>
                        <span>15.00</span>
                      </div>
                    </Col>
                  </li>
                ) : null
              )
              .concat(
                (val.status === "Completed" ||
                  val.status === "Processing" ||
                  val.status === "Reschedule" ||
                  val.status === "Training") &&
                  dateComparison ? (
                  <li key={"travel"}>
                    <Col span={24} className="p-2">
                      <div className="line-item">
                        <span>Driving</span>
                        <span>
                          {roundOffNumber(
                            Math.round(travelStipendAmount * 100) / 100
                          ).toFixed(2)}
                        </span>
                      </div>
                    </Col>
                  </li>
                ) : (
                  []
                )
              )
              .concat(
                val.status === "Reschedule" && dateComparison ? (
                  <li key={"travel-fare"}>
                    <Col span={24} className="p-2">
                      <div className="line-item">
                        <span>Driving</span>
                        <span>{travelStipendAmount.toFixed(2)}</span>
                      </div>
                    </Col>
                  </li>
                ) : (
                  []
                )
              )
              .concat(
                (val.status === "Completed" ||
                  val.status === "Processing" ||
                  val.status === "Reschedule" ||
                  val.status === "Training") &&
                  isPastEffectiveDate(val) ? (
                  <li key={"DistTravelled"}>
                    <Col span={24} className="p-2">
                      <div className="line-item">
                        <span>
                          Variable Trip Charge ({distTravFns[val._id]} miles)
                        </span>
                        <span>
                          {roundOffNumber(
                            distTravFns[val._id] * costMultiplierForDist
                          ).toFixed(2)}
                        </span>
                      </div>
                    </Col>
                  </li>
                ) : (
                  []
                )
              )}
          </ul>

          <span className="day-total">
            Day Total: <strong>{getPayValue(val).toFixed(2)}</strong>
          </span>
        </Col>
      </Row>
    );
  };
  let tempArrayForWeekTotal = [];
  let sum = 0;
  const onPayValueChange = (values) => {
    setDistTravCost(null);
    if (!isNaN(values)) {
      let valueIn = roundOffNumber(values);
      let nums = Number(`${valueIn}`);
      tempArrayForWeekTotal.push(nums);
      if (tempArrayForWeekTotal.length === filteredShoot.length) {
        for (var j = 0; j < tempArrayForWeekTotal.length; j++) {
          if (!isNaN(values)) {
            sum += tempArrayForWeekTotal[j];
          }
        }
        if (!isNaN(sum)) {
          setDistTravCost(sum);
        }
      }
    }
  };

  const columns = [
    {
      title: "HS#",
      dataIndex: "hsn",
      key: "hsn",
      render: (hsn, record) => (
        <Link to={`/admin/shoots/${record._id}`}>
          {hsn}{" "}
          {!record.invoice[0]?.photographer_total_pay ? (
            <ExclamationCircleOutlined
              style={{ color: "red", marginLeft: 5 }}
            />
          ) : null}
        </Link>
      ),
    },
    {
      title: "Shoot Date",
      dataIndex: "start",
      key: "start",
      render: (start, record) => (
        <span>{moment(record.events.start).format("ddd, MM/DD/YY")}</span>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "hsn",
      render: (address, record) => (
        <span>
          {formatUsAddress(
            record.address.full,
            record.address.street,
            record.address.apt,
            record.address.city,
            record.address.state,
            record.address.zip
          )}{" "}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "hsn",
      render: (status, record) => (
        <span>
          <Tag color={getShootColor(status)}>{status}</Tag>
        </span>
      ),
    },
    {
      title: "Pay",
      render: (invoice, record) => (
        <NumberFormat
          value={getPayValue(record)}
          onValueChange={onPayValueChange(
            record.status === "Completed" || record.status === "Processing"
              ? roundOffNumber(record.invoice[0]?.photographer_total_pay) +
                  (record.status !== "Reschedule" &&
                  record.status !== "Tentative" &&
                  record.status !== "Training"
                    ? roundOffNumber(
                        getBasePay(currentPhotographer, record.events.start)
                      )
                    : 0) +
                  roundOffNumber(
                    (record.status === "Completed" ||
                      record.status === "Processing" ||
                      record.status === "Training" ||
                      record.status === "Reschedule" ||
                      record.status === "Tentative" ||
                      record.status === "Scheduled") &&
                      isPastEffectiveDate(record)
                      ? travelStipendAmount
                      : 0
                  ) +
                  roundOffNumber(
                    distTravFns[record._id] * costMultiplierForDist
                  )
              : roundedCurrency(
                  record.invoice[0].photographer_total_pay +
                    (record.status !== "Reschedule" &&
                    record.status !== "Training" &&
                    record.status !== "Tentative"
                      ? getBasePay(currentPhotographer, record.events.start)
                      : record.status === "Reschedule" ||
                        record.status === "Training"
                      ? roundOffNumber(travelStipendAmount)
                      : 0)
                )
          )}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      ),
    },
  ];

  const setTimeBasedOnParam = () => {
    const value = queryString.parse(history.location.search);
    let start;
    let end;
    if (value.date) {
      const startDate = moment(value.date, "MM-DD-YY");
      start = moment(startDate);
      end = moment(startDate).add(6, "days");
      setTime({ start, end });
    } else {
      start = moment(moment()._d, "YYYY/MM/DD")
        .startOf("week")
        .subtract(2, "days");
      end = moment().endOf("week").subtract(2, "days");
      setTime({ start, end });
    }
    return start;
  };

  useEffect(() => {
    setTimeBasedOnParam();
    setDistTravCost(null);
  }, []);

  // Base pay is effectice 3/3/2023
  const prevBasePay = 15; // old base pay
  const cutOffDate = moment("2023-03-03"); // effective date for new base pay

  const getBasePay = (employee, invoiceDate) => {
    const momentInvoiceDate = moment(invoiceDate);
    const payMultiplier = employee.pay_multiplier || 1; // default to 1 if payMultiplier is not set
    if (momentInvoiceDate.isBefore(cutOffDate)) {
      return parseFloat((prevBasePay * payMultiplier).toFixed(2));
    }
    return parseFloat((employee.base_pay * payMultiplier).toFixed(2));
  };

  useEffect(() => {
    if (time.start) {
      let date = time.start;
      const insertDate = (date) => {
        if (date <= time.end._d) {
          array.push(date._d);
          insertDate(moment(date).add(1, "days"));
        } else {
          setRange([...array]);
          return;
        }
      };
      const array = [];
      insertDate(date);
    }
  }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      setTimeBasedOnParam();
    };
  }, []);
  useEffect(() => {
    if (
      (photographerShoots && match === currentPhotographer?._id) ||
      photographerShoots
    ) {
      let grandTotal = 0;
      const shoots = photographerShoots.filter(
        (shoot) =>
          range.filter((date) => {
            return moment(moment(date).format("YYYY/MM/DD")).isSame(
              shoot?.events?.start?.split("T")[0]
            );
          })[0]
      );
      let sum;
      shoots.forEach((shoot) => {
        const localDateComparison = isPastEffectiveDate(shoot);
        const invoices = shoot.invoice;
        sum =
          invoices[0].photographer_total_pay +
          (shoot.status !== "Reschedule" &&
          shoot.status !== "Tentative" &&
          shoot.status !== "Training"
            ? roundOffNumber(
                getBasePay(currentPhotographer, shoot.events.start)
              )
            : 0) +
          (localDateComparison
            ? shoot.status === "Completed"
              ? travelStipendAmount
              : 0
            : 0);
        grandTotal += sum;
      });
      setGrandTotal(grandTotal);

      let homeAddress = `${currentPhotographer.geometry.coordinates[1]},${currentPhotographer.geometry.coordinates[0]}`;
      //sort the completed orders in events.start ASC
      let sortedArr = shoots
        .filter((shoot) => {
          return (
            shoot.status === "Completed" ||
            shoot.status === "Processing" ||
            shoot.status === "Reschedule" ||
            shoot.status === "Training" ||
            shoot.status === "Tentative" ||
            shoot.status === "Scheduled"
          );
        })
        .sort((a, b) => {
          if (a?.events?.start && b?.events?.start) {
            let firstDate = new Date(a.events.start);
            let secondDate = new Date(b.events.start);
            if (firstDate >= secondDate) return 1;
            else return -1;
          } else return 0;
        });
      //Segregate based on date
      const dateSeggregatedObj = sortedArr.reduce((acc, shoot) => {
        let datekey = moment(shoot.events.start).format("DD-MM-YYYY");
        return { ...acc, [datekey]: (acc[datekey] || []).concat(shoot) };
      }, {});
      //create distance calc array for each shoot.
      const setOfAddresses = Object.keys(dateSeggregatedObj).reduce(
        (acc, date) => {
          let dateWiseShoots = dateSeggregatedObj[date];
          const queryList = [
            homeAddress,
            ...dateWiseShoots.map(
              (shoot) =>
                `${shoot?.address.geometry.coordinates[1]},${shoot?.address.geometry.coordinates[0]}`
            ),
          ];
          const shootDistMap = {};
          for (let i = 0; i < queryList.length - 1; i++) {
            (async () => {
              let res = await fetchDistanceArrayAndTotal(
                currentPhotographer._id,
                [queryList[i], queryList[i + 1]]
              );
              if (i === queryList.length - 2) {
                let tempRes = await fetchDistanceArrayAndTotal(
                  currentPhotographer._id,
                  [queryList[0], queryList[queryList.length - 1]]
                );
                res = {
                  data: { data: res?.data?.data + tempRes?.data?.data },
                  success: true,
                };
              }
              if (res.success) {
                let isAfterEffectiveDate = isPastEffectiveDate(
                  dateWiseShoots[i]
                );
                if (isAfterEffectiveDate) {
                  setDistTravFns((s) => ({
                    ...s,
                    [dateWiseShoots[i]._id]: res?.data?.data,
                  }));
                  setGrandTotal((s) => {
                    return (
                      s +
                      roundOffNumber(res?.data?.data * costMultiplierForDist)
                    );
                  });
                }
              }
            })();
            dateWiseShoots[i]?._id && (shootDistMap[dateWiseShoots[i]._id] = 0);
          }
          return { ...acc, ...shootDistMap };
        },
        {}
      );
      setDistTravFns(setOfAddresses);

      setFilteredShoot(shoots);
    }
  }, [range, photographerShoots, test]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let valt = 0;
    if (filteredShoot.length)
      filteredShoot.map((shoot) => {
        valt = valt + getTotalValue(shoot, shoot.invoice);
      });
    setweekTotal(valt.toFixed(2));
  }, [filteredShoot, distTravFns]);

  const dateHandlerNext = () => {
    setDistTravCost(null);
    const startDate = moment(time.start._d).add(7, "days");
    const endDate = moment(time.end._d).add(7, "days");
    const params = new URLSearchParams();
    params.append("date", startDate.format("MM-DD-YY"));
    history.push({ search: params.toString() });
    return setTime({
      end: endDate,
      start: startDate,
    });
  };
  const dateHandlerPrev = () => {
    setDistTravCost(null);
    const startDate = moment(time.start._d).subtract(7, "days");
    const endDate = moment(time.end._d).subtract(7, "days");
    const params = new URLSearchParams();
    params.append("date", startDate.format("MM-DD-YY"));
    history.push({ search: params.toString() });
    return setTime({
      start: startDate,
      end: endDate,
    });
  };
  return (
    <div className="photographer">
      <Row
        className="$mainBg mb-2 mt-2"
        style={{
          backgroundColor: "#f3f7ff",
        }}
      >
        <Col span={12}>
          <WeeklyPayOverview
            startDate={moment(time.start).format("MM-DD-YY")}
            endDate={moment(time.end).format("MM-DD-YY")}
          />
          <Button
            className="ml-2"
            onClick={() => updatePhotographerWeekTotal()}
          >
            {loadingWeeklyTotal ? <SyncOutlined spin /> : <SyncOutlined />} Sync
          </Button>
        </Col>
        <Col span={12}>
          <Row align="end">
            <Col>
              <div className="nav-arrow">
                <ArrowLeftOutlined
                  onClick={dateHandlerPrev}
                  style={{ color: "#191919" }}
                />
              </div>
            </Col>
            <Col>
              <div className="calendar-nav">
                <CalendarOutlined
                  style={{ color: "#f54b64" }}
                  className="mr-2"
                />{" "}
                {moment(time.start._d).format("MM/DD/YY")}-
                {moment(time.end._d).format("MM/DD/YY")}
              </div>
            </Col>
            <Col>
              <div className="nav-arrow">
                {" "}
                <ArrowRightOutlined
                  onClick={dateHandlerNext}
                  style={{ color: "#191919" }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Spin spinning={loader}>
          <Table
            dataSource={filteredShoot}
            columns={columns}
            expandable={{ expandedRowRender }}
            rowKey={(record) => record._id}
            pagination={false}
          />
        </Spin>

        {grandTotal !== 0 ? (
          <Row>
            <Col span={12}>
              <div className="week-total">
                Week Total Distance:{" "}
                <strong>{`${weeklyDistance.toFixed(1)} miles`}</strong>
              </div>
            </Col>
            <Col span={12}>
              <div className="week-total">
                Week Total:{" "}
                <span className="amount text-success">{`$${
                  roundOffNumber(weekTotal).toFixed(2) || 0.0
                }`}</span>
              </div>
            </Col>
          </Row>
        ) : distTravCost ? (
          <Row>
            <Col span={12}>
              <div className="week-total">
                Week Total Distance:{" "}
                <span className="amount">{`${weeklyDistance} miles`}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="week-total">
                Week Total:{" "}
                {distTravCost ? (
                  <span className="amount">{`$${distTravCost.toFixed(
                    2
                  )}`}</span>
                ) : (
                  0(
                    <span className="amount">{`$${
                      roundOffNumber(grandTotal) || 0.0
                    }`}</span>
                  )
                )}
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  photographer: state.photographer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPhotographerShoots,
  fetchPhotographerByUserId,
  fetchDistanceArrayAndTotal,
  updatePhotographerForWeekTotal,
  updateInvoice,
})(PayBreakdown);
